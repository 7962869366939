import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import './App.css';
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


function App() {

  const [values, setValues] = React.useState({
    kwotaKredytu: 490000,
    iloscRat: 300,
    kapital: 1633.33333,
    ubezpSplatProcent: 0.035,
    spezpSplatMaxMiesiace: 36,
    ubezpNieruchProcent: 0.0096,
    spezpNieruchMaxMiesiace: 300,
    prowizja: 0,
    marza: 1.95,
    oproc1rok: 1.95,
    wibor: 1.5,
    stale: 5.5,
    oprocentowanie: 3.45,
    uzyjStalegoOprocentowania: false,
    oproc1rokInaczej: false,
    miesiaceStalegoOproc: 60,
    trybNadplat: "doRaty",
    wartoscNadplaty: 0,
    miesiecyNadplaty: 300,
    nadplataSkraca: true,
  });

  const [tableData, setTableData] = React.useState([]);
  const [superSum, setSuperSum] = React.useState(0);
  const [superMonths, setSuperMonths] = React.useState(0);
  const [superProwizja, setSuperProwizja] = React.useState(0);


  const computeFinish = (result) => {
    //console.log(result);
    setTableData(result);
  }

  const compute = () => {
    let saldo = parseFloat(values.kwotaKredytu);
    let result = [];
    let sum = 0;
    let maxmonth = 0;

    for (let miesiac = 1; miesiac <= values.iloscRat; miesiac++) {
      //console.log("miesiac "+miesiac);
      let odsetki = 0;
      if (values.oproc1rokInaczej && miesiac <= 12) {
        odsetki = saldo * (parseFloat(values.oproc1rok) / 100 / 12);
      } else if (values.uzyjStalegoOprocentowania && miesiac <= values.miesiaceStalegoOproc) {
        odsetki = saldo * (parseFloat(values.stale) / 100 / 12);
      } else {
        odsetki = saldo * (parseFloat(values.oprocentowanie) / 100 / 12);
      }


      // console.log("odsetki "+odsetki);
      let ubezpieczenieSplat = 0;
      if (miesiac <= parseInt(values.spezpSplatMaxMiesiace)) {
        ubezpieczenieSplat = saldo * (parseFloat(values.ubezpSplatProcent) / 100);
        //console.log("ubezpieczenieSplat "+ubezpieczenieSplat); 
      }
      let ubezpieczenieNieruchomosci = 0;
      if (miesiac <= parseInt(values.spezpNieruchMaxMiesiace)) {
        ubezpieczenieNieruchomosci = parseFloat(values.kwotaKredytu) * (parseFloat(values.ubezpNieruchProcent) / 100);
        //console.log("ubezpieczenieNieruchomosci "+ubezpieczenieNieruchomosci); 
      }
      let kapital = 0;
      if (values.nadplataSkraca) kapital = parseFloat(values.kapital);
      else kapital = saldo / (parseFloat(values.iloscRat) - miesiac + 1);
      // console.log("kapital init "+kapital+" = "+saldo+" / "+(parseFloat(values.iloscRat)-miesiac+1));     
      if (values.trybNadplat === "doRaty" && parseFloat(values.wartoscNadplaty) >= (kapital + odsetki) && miesiac <= parseInt(values.miesiecyNadplaty)) {
        kapital = parseFloat(values.wartoscNadplaty) - odsetki - ubezpieczenieNieruchomosci - ubezpieczenieSplat;
        if (kapital < 0) kapital = 0;
      } else if (values.trybNadplat === "stala" && miesiac <= parseInt(values.miesiecyNadplaty)) {
        kapital += parseFloat(values.wartoscNadplaty);
      }
      if (kapital >= (saldo  /*ubezpieczenieNieruchomosci - ubezpieczenieSplat - odsetki*/)) {
        kapital = saldo  /*ubezpieczenieNieruchomosci - ubezpieczenieSplat - odsetki*/;
      }
      // console.log("kapital "+kapital); 

      let rata = ubezpieczenieNieruchomosci + ubezpieczenieSplat + odsetki + kapital;
      sum += rata;
      maxmonth = miesiac;
      // console.log("rata "+rata); 

      saldo -= kapital;

      //  console.log("saldo "+saldo);

      result.push({
        miesiac,
        saldo,
        odsetki,
        ubezpieczenieSplat,
        ubezpieczenieNieruchomosci,
        kapital,
        rata
      })

      if (saldo < 10) {
        break;

      }

    }
    setSuperSum(sum);
    setSuperProwizja(parseFloat(values.kwotaKredytu) * (parseFloat(values.prowizja) / 100));
    setSuperMonths(maxmonth);
    computeFinish(result);
  }


  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  React.useEffect(() => {
    setValues({
      ...values,
      kapital: parseFloat(values.kwotaKredytu) / parseFloat(values.iloscRat),
    });
  }, [values.kwotaKredytu, values.iloscRat])

  React.useEffect(() => {
    setValues({
      ...values,
      oprocentowanie: parseFloat(values.marza) + parseFloat(values.wibor),
    });
  }, [values.marza, values.wibor])

  const changeOprocentowanie = (event) => {
    setValues({
      ...values,
      uzyjStalegoOprocentowania: event.target.checked,
    });
  }

  const changeNadplata = (event) => {
    setValues({
      ...values,
      nadplataSkraca: event.target.checked,
    });
  }

  const changeOproc1rok = (event) => {
    setValues({
      ...values,
      oproc1rokInaczej: event.target.checked,
    });
  }

  return (
    <div className="App">
      <div className="inputsContainer">
        <TextField
          name="kwotaKredytu"
          value={values.kwotaKredytu}
          onChange={handleChange}
          label="Kwota kredytu"
          variant="standard"
          fullWidth
        />

        <TextField
          name="iloscRat"
          value={values.iloscRat}
          onChange={handleChange}
          label="Ilość rat"
          variant="standard"
          fullWidth

        />

        <TextField
          name="kapital"
          value={values.kapital}
          onChange={handleChange}
          label="kapitał jednej raty"
          variant="standard"
          fullWidth
          disabled
        />
      </div>
      <div className="inputsContainer">
        <TextField
          name="ubezpSplatProcent"
          value={values.ubezpSplatProcent}
          onChange={handleChange}
          label="Ubezpieczenie spłat procent"
          variant="standard"
          fullWidth
        />

        <TextField
          name="spezpSplatMaxMiesiace"
          value={values.spezpSplatMaxMiesiace}
          onChange={handleChange}
          label="Ubezpieczenie spłat ile max miesięcy"
          variant="standard"
          fullWidth
        />

        <TextField
          name="ubezpNieruchProcent"
          value={values.ubezpNieruchProcent}
          onChange={handleChange}
          label="Ubezpieczenie nieruchomości procent"
          variant="standard"
          fullWidth
        />

        <TextField
          name="spezpNieruchMaxMiesiace"
          value={values.spezpNieruchMaxMiesiace}
          onChange={handleChange}
          label="Ubezpieczenie nieruchomości ile max miesięcy"
          variant="standard"
          fullWidth
        />
      </div>

      <div className="inputsContainer">

        <TextField
          name="prowizja"
          value={values.prowizja}
          onChange={handleChange}
          label="Prowizja"
          variant="standard"
          fullWidth

        />

        <TextField
          name="marza"
          value={values.marza}
          onChange={handleChange}
          label="Marża"
          variant="standard"
          fullWidth

        />



        <TextField
          name="wibor"
          value={values.wibor}
          onChange={handleChange}
          label="WIBOR"
          variant="standard"
          fullWidth

        />

        <TextField
          name="oprocentowanie"
          value={values.oprocentowanie}
          onChange={handleChange}
          label="Oprocentowanie zmienne"
          variant="standard"
          fullWidth
          disabled
        />

        <TextField
          name="stale"
          value={values.stale}
          onChange={handleChange}
          label="Oprocentowanie stałe"
          variant="standard"
          fullWidth
          disabled={!values.uzyjStalegoOprocentowania}
          style={{ display: !values.uzyjStalegoOprocentowania ? "none" : "" }}
        />

        <TextField
          name="miesiaceStalegoOproc"
          value={values.miesiaceStalegoOproc}
          onChange={handleChange}
          label="Ile miesięcy stałego"
          variant="standard"
          fullWidth
          disabled={!values.uzyjStalegoOprocentowania}
          style={{ display: !values.uzyjStalegoOprocentowania ? "none" : "" }}
        />

        <TextField
          name="oproc1rok"
          value={values.oproc1rok}
          onChange={handleChange}
          label="Oprocentowanie 1 rok"
          variant="standard"
          fullWidth
          disabled={!values.oproc1rokInaczej}
          style={{ display: !values.oproc1rokInaczej ? "none" : "" }}
        />



        <FormControlLabel control={<Switch checked={values.oproc1rokInaczej} onChange={changeOproc1rok} />} label="Inny 1 rok" />
        <FormControlLabel control={<Switch checked={values.uzyjStalegoOprocentowania} onChange={changeOprocentowanie} />} label="Użyj stałego" />
      </div>


      <div className="inputsContainer">

        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name="trybNadplat"
          value={values.trybNadplat}
          label="Nadpłacanie rat"
          onChange={handleChange}
        >
          <MenuItem value={"doRaty"}>Nadpłać co miesiąc do wysokości raty</MenuItem>
          <MenuItem value={"stala"}>Nadpłać co miesiąc stałą wartość</MenuItem>
        </Select>

        <TextField
          name="wartoscNadplaty"
          value={values.wartoscNadplaty}
          onChange={handleChange}
          label="Ile nadpłacać"
          variant="standard"
          fullWidth
        />

        <TextField
          name="miesiecyNadplaty"
          value={values.miesiecyNadplaty}
          onChange={handleChange}
          label="Przez ile miesięcy nadpłacać"
          variant="standard"
          fullWidth
        />

        <FormControlLabel control={<Switch checked={values.nadplataSkraca} onChange={changeNadplata} />} label="Nadpłata skraca" />



        <Button onClick={compute} variant="contained">Oblicz</Button>
      </div>

      <div className="inputsContainer">
        prowizja: &nbsp;&nbsp;<span style={{ fontWeight: "bold" }}> {superProwizja.toFixed(2)}</span>
        &nbsp;&nbsp;&nbsp;koszt rat: &nbsp;&nbsp;<span style={{ fontWeight: "bold" }}> {superSum.toFixed(2)}</span>
        &nbsp;&nbsp;&nbsp;do zapłaty: &nbsp;&nbsp;<span style={{ fontWeight: "bold" }}> {(superSum + superProwizja).toFixed(2)}</span>
        &nbsp;&nbsp;&nbsp;koszt kredytu: &nbsp;&nbsp;<span style={{ fontWeight: "bold" }}> {(superSum + superProwizja - parseFloat(values.kwotaKredytu)).toFixed(2)}</span>
        &nbsp;&nbsp;&nbsp;spłata zejdzie: &nbsp;&nbsp;<span style={{ fontWeight: "bold" }}> {Math.floor(superMonths / 12)} lat i {superMonths - Math.floor(superMonths / 12) * 12} miesięcy</span>
      </div>
      <div className="inputsContainer">
        <TableContainer component={Paper}>
          <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Miesiąc</TableCell>
                <TableCell align="right">Saldo</TableCell>
                <TableCell align="right">Rata</TableCell>
                <TableCell align="right">Odsetki</TableCell>
                <TableCell align="right">Kapitał</TableCell>
                <TableCell align="right">Ub. nieruchomości</TableCell>
                <TableCell align="right">Ub. spłaty</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell >rok {Math.ceil(row.miesiac / 12) - 1} miesiąc {row.miesiac - (Math.ceil(row.miesiac / 12) - 1) * 12} ({row.miesiac})</TableCell>
                  <TableCell align="right">{row.saldo.toFixed(2)}</TableCell>
                  <TableCell align="right">{row.rata.toFixed(2)}</TableCell>
                  <TableCell align="right">{row.odsetki.toFixed(2)}</TableCell>
                  <TableCell align="right">{row.kapital.toFixed(2)}</TableCell>
                  <TableCell align="right">{row.ubezpieczenieNieruchomosci.toFixed(2)}</TableCell>
                  <TableCell align="right">{row.ubezpieczenieSplat.toFixed(2)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

    </div>
  );
}

export default App;
